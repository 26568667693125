.menu-container {
  display: flex;
  z-index: 2;
  gap: 1rem;
}
.menu-tile {
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  transition: all 0.3s;
  cursor: pointer;
  border: 1px solid;
  border-color: var(--icon-color);
}

.menu-tile:hover,
.menu-tile:active {
}

.menu-tile #icon {
  color: var(--icon-color);
  font-size: 2rem;
  transition: all 0.3s ease-out;
}

.menu-tile:hover > #icon {
  color: var(--icon-hover-color);
}

.menu-tile:hover {
  transform: scale(1.2);
  border-color: var(--icon-hover-color);
}
